import { otherCurrencyQuery } from '../TONFields';
import { outMsgQuery, inMsgDescrQuery } from '../TONMessage/fields';
import TONClient from '../TONClient';

const { formatDEC } = TONClient;

export const hashField = 'hash';
export const idField = 'id';

// TODO Replace on short fields
export const listFields = 'id seq_no workchain_id shard gen_utime tr_count';

export const minimalFields = 'hash seq_no workchain_id shard';

export const shortFields = `${minimalFields} gen_utime tr_count gen_software_version`;

export const blockRefQuery = `{
    end_lt
    seq_no
    root_hash
    file_hash
}`;

export const accountBlocksQuery = `{
    account_addr
    transactions {
        lt
        transaction_id
        total_fees
    }
    old_hash
    new_hash
    tr_count
}`;

export const valueFlowQuery = `{
    to_next_blk${formatDEC}
    exported${formatDEC}
    fees_collected${formatDEC}
    created${formatDEC}
    imported${formatDEC}
    from_prev_blk${formatDEC}
    minted${formatDEC}
    fees_imported${formatDEC}
}`;

export const votingParamsQuery = `{
    min_tot_rounds
    max_tot_rounds
    min_wins
    max_losses
    min_store_sec
    max_store_sec
    bit_price
    cell_price
}`;

export const p6Query = `{
    mint_new_price
    mint_add_price
}`;

export const p8Query = `{
    version
    capabilities
}`;

export const p12Query = `{
    workchain_id
    enabled_since
    actual_min_split
    min_split
    max_split
    active
    accept_msgs
    flags
    zerostate_root_hash
    zerostate_file_hash
    version
    basic
    vm_version
    vm_mode
    min_addr_len
    max_addr_len
    addr_len_step
    workchain_type_id
}`;

export const p14Query = `{
    masterchain_block_fee
    basechain_block_fee
}`;

export const p15Query = `{
    validators_elected_for
    elections_start_before
    elections_end_before
    stake_held_for
}`;

export const p16Query = `{
    max_validators
    max_main_validators
    min_validators
}`;

export const p17Query = `{
    min_stake
    max_stake
    min_total_stake
    max_stake_factor
}`;

export const p18Query = `{
    utime_since
    bit_price_ps
    cell_price_ps
    mc_bit_price_ps
    mc_cell_price_ps
}`;

export const gasLimitPriceQuery = `{
    gas_price
    gas_limit
    special_gas_limit
    gas_credit
    block_gas_limit
    freeze_due_limit
    delete_due_limit
    flat_gas_limit
    flat_gas_price
}`;

const blockLimQuery = `{
    underload
    soft_limit
    hard_limit
}`;
export const blockLimitsQuery = `{
    bytes ${blockLimQuery}
    gas ${blockLimQuery}
    lt_delta ${blockLimQuery}
}`;

export const msgForwardPricesQuery = `{
    lump_price
    bit_price
    cell_price
    ihr_price_factor
    first_frac
    next_frac
}`;

export const p28Query = `{
    mc_catchain_lifetime
    shard_catchain_lifetime
    shard_validators_lifetime
    shard_validators_num
}`;

export const p29Query = `{
    round_candidates
    next_candidate_delay_ms
    consensus_timeout_ms
    fast_attempts
    attempt_duration
    catchain_max_deps
    max_block_bytes
    max_collated_bytes
}`;

export const validatorSetQuery = `{
    utime_since
    utime_until
    total
    total_weight
    list {
        public_key
        adnl_addr
        weight
    }
}`;

export const p39Query = `{
    adnl_addr
    temp_public_key
    seqno
    valid_until
    signature_r
    signature_s
}`;

export const p40Query = `{
    slashing_period_mc_blocks_count
    resend_mc_blocks_count
    min_samples_count
    collations_score_weight
    signing_score_weight
    min_slashing_protection_score
    z_param_numerator
    z_param_denominator
}`;

export const shardHashesQuery = `{
    workchain_id
    shard
    descr {
        seq_no
        reg_mc_seqno
        start_lt${formatDEC}
        end_lt${formatDEC}
        root_hash
        file_hash
        before_split
        before_merge
        want_split
        want_merge
        nx_cc_updated
        flags
        next_catchain_seqno
        next_validator_shard
        min_ref_mc_seqno
        gen_utime
        split_type
        split_type_name
        split
        fees_collected${formatDEC}
        funds_created${formatDEC}
    }
}`;

export const shardFeesQuery = `{
    workchain_id
    shard
    fees${formatDEC}
    create${formatDEC}
}`;

const masterConfigQuery = (needP40: boolean = false) => `
    {
        p0 p1 p2 p3 p4
        p6 ${p6Query}
        p7 ${otherCurrencyQuery}
        p8 ${p8Query}
        p9
        p10
        p11 {
            normal_params ${votingParamsQuery}
            critical_params ${votingParamsQuery}
        }
        p12 ${p12Query}
        p14 ${p14Query}
        p15 ${p15Query}
        p16 ${p16Query}
        p17 ${p17Query}
        p18 ${p18Query}
        p20 ${gasLimitPriceQuery}
        p21 ${gasLimitPriceQuery}
        p22 ${blockLimitsQuery}
        p23 ${blockLimitsQuery}
        p24 ${msgForwardPricesQuery}
        p25 ${msgForwardPricesQuery}
        p28 ${p28Query}
        p29 ${p29Query}
        p31
        p32 ${validatorSetQuery}
        p33 ${validatorSetQuery}
        p34 ${validatorSetQuery}
        p35 ${validatorSetQuery}
        p36 ${validatorSetQuery}
        p37 ${validatorSetQuery}
        p39 ${p39Query}
        ${needP40 ? `p40 ${p40Query}` : ''}
    }`;

export const masterQuery = (needP40: boolean) => `
    master {
        shard_hashes { workchain_id }
    }
`;

export const zeroStateQuery = (needP40: boolean) => `
    id
    workchain_id
    global_id
    total_balance
    total_balance_other {
        currency
        value
    }
`;

export const zeroStateForValidatorsQuery = `
    
`;

export const additionalFields = (needP40: boolean) => `
    status
    global_id
    want_split
    after_merge
    gen_catchain_seqno
    flags
    prev_ref ${blockRefQuery}
    prev_alt_ref ${blockRefQuery}
    prev_vert_ref ${blockRefQuery}
    prev_vert_alt_ref ${blockRefQuery}

    version
    gen_validator_list_hash_short
    before_split
    after_split
    want_merge
    vert_seq_no
    start_lt${formatDEC}
    end_lt${formatDEC}
    min_ref_mc_seqno
    prev_key_block_seqno
    gen_software_version
    gen_software_capabilities
    value_flow ${valueFlowQuery}
    in_msg_descr ${inMsgDescrQuery}
    rand_seed
    out_msg_descr ${outMsgQuery}
    account_blocks ${accountBlocksQuery}
`;

export const binaryFields = 'boc';

export const masterBlockShardsFields = `
    seq_no
`;
